import { StaticImage } from "gatsby-plugin-image"
import React, { FC } from "react"

import { About } from "../components/About"
import { Game } from "../components/Game"
import { Hero } from "../components/Hero"
import { Layout } from "../components/Layout"
import { Menu } from "../components/Menu"
import { Shop } from "../components/Shop"
import { Spec } from "../components/Spec"
import { Story } from "../components/Story"

const PomikeBanner: FC = () => {
  return (
    <a
      href="https://pomike.tete-a-tete.ink/"
      className="flex items-center bg-pyellow-50 max-w-5xl w-full md:w-11/12 lg:w-4/6 shadow-lg pr-4 md:pr-8 rounded hover:ring-offset-pyellow-50"
    >
      <p className="flex-grow font-bold text-xl md:text-2xl text-center text-black/60">
        前作「ポミケ中止」も好評頒布中！
      </p>
      <StaticImage
        alt="ポミケ中止"
        src="../images/banners/pomike-banner.png"
        className="flex-end"
        height={128}
      />
    </a>
  )
}

const Page: FC = () => {
  return (
    <Layout pageTitle="">
      <div className="flex flex-col gap-8 md:gap-10 p-2 mb-20">
        <Hero />
        <About />
        <Story />
        <Game />
        <Shop />
        <Spec />
        <section className="flex justify-center flex-col items-center relative">
          <PomikeBanner />
        </section>
      </div>
      <Menu />
    </Layout>
  )
}

export default Page
