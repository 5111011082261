import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React, { FC } from "react"

import { Carousel } from "./Carousel"
import { Section } from "./Section"

export const About: FC = () => {
  const bgImg = useStaticQuery(
    graphql`
      query {
        images: file(relativePath: { eq: "parts/beer01.png" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Section id="about" className="gap-6 md:gap-8">
      <Carousel />
      <BackgroundImage
        fluid={bgImg.images.childImageSharp.fluid}
        className="bg-no-repeat bg-contain bg-scroll bg-right-bottom "
      >
        <div className=" stroke-1 bg-white/20 flex flex-col gap-2">
          <h3 className="col-start-1 row-start-1 col-span-8 text-2xl font-bold stroke-2">
            ビールを飲んでハッピーになろう♪
            <br />
            コンポーネントが楽しいわいわいブラフゲーム！
          </h3>
          <ul className="cols-start-1 mt-2 list-disc ml-4 stroke-1">
            <li>木製トークンをめくって、黄色ならビール、白なら泡！</li>
            <li>
              トークンをビールに見立てて、自分のビアジョッキに注いで飲もう
            </li>
            <li>
              たくさんビールが飲めると、たくさんハッピーになれます！
              <br />
              一番ハッピーになった人が勝ち！
            </li>
          </ul>
        </div>
      </BackgroundImage>
    </Section>
  )
}
