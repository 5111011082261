import { ImFacebook } from "@react-icons/all-files/im/ImFacebook"
import { IoLogoTwitter } from "@react-icons/all-files/io/IoLogoTwitter"
import { RiLineFill } from "@react-icons/all-files/ri/RiLineFill"
import { StaticImage } from "gatsby-plugin-image"
import React, { FC } from "react"

export const Hero: FC = () => {
  const url = "https://beer.tete-a-tete.ink/"

  const shareMsg = `ビールを飲んでハッピーになろう♪ 3〜5人用ボードゲーム！ 「ビールと泡」`
  const hashTags = `#ゲムマ #ゲームマーケット #ビールと泡`

  const twUrl = new URL("https://twitter.com/intent/tweet")
  twUrl.searchParams.set("text", `${shareMsg} ${hashTags}`)
  twUrl.searchParams.set("url", url)

  const fbUrl = new URL("https://www.facebook.com/sharer.php")
  fbUrl.searchParams.set("u", url)

  const lnUrl = new URL("https://social-plugins.line.me/lineit/share")
  lnUrl.searchParams.set("url", url)

  const btnCls =
    "rounded-full bg-current h-10 w-10 sm:h-14 sm:w-14 flex items-center justify-center text-white shadow"
  const iconCls = "h-8 w-8 sm:h-10 sm:w-10"

  return (
    <section className="flex flex-col items-center justify-center gap-4">
      <div id="top" />
      <StaticImage
        placeholder="none"
        src="../images/forTOP/bannar_white.png"
        alt=""
        className="max-w-6xl w-full lg:w-5/6"
        width={1600}
        loading="eager"
      />
      <div className="max-w-5xl w-full md:w-11/12 lg:w-4/6 gap-2 md:gap-3 flex flex-col md:flex-row bg-white rounded-2xl px-3 md:px-8 py-8 mt-8 bg-opacity-95 relative ">
        <p className="leading-8 sm:text-xl flex-grow font-bold indent-0">
          2022-04-24 22:00: ゲームマーケットでは完売ありがとうございました！
          <br />
          2022-05-04: Boothでの再販受付開始しました。
        </p>
        <div className="flex gap-3 justify-center">
          <a
            href={twUrl.toString()}
            className={btnCls}
            style={{ backgroundColor: "#1C9CEA" }}
            onClick={() =>
              window.gtag("event", "share", {
                method: "Twitter",
                item_id: window.location.pathname,
              })
            }
          >
            <IoLogoTwitter className={iconCls} />
          </a>
          <a
            href={fbUrl.toString()}
            className={btnCls}
            style={{ backgroundColor: "#1877F2" }}
            onClick={() =>
              window.gtag("event", "share", {
                method: "Facebook",
                item_id: window.location.pathname,
              })
            }
          >
            <ImFacebook className={iconCls} />
          </a>
          <a
            href={lnUrl.toString()}
            className={btnCls}
            style={{ backgroundColor: "#00C815" }}
            onClick={() =>
              window.gtag("event", "share", {
                method: "LINE",
                item_id: window.location.pathname,
              })
            }
          >
            <RiLineFill className={iconCls} />
          </a>
        </div>
      </div>
    </section>
  )
}
