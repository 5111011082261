import { Disclosure, Transition } from "@headlessui/react"
import { BiArrowToTop } from "@react-icons/all-files/bi/BiArrowToTop"
import scrollTo from "gatsby-plugin-smoothscroll"
import React, { FC } from "react"

const jump = (selector: string) => {
  scrollTo(selector)
  return false
}

export const Menu: FC = () => {
  const links: { link: string; label?: string }[] = [
    { link: "about" },
    { link: "story" },
    { link: "game" },
    // { link: "qa", label: "Q&A" },
    { link: "shop" },
    { link: "spec" },
  ]

  return (
    <div className="fixed bottom-0 w-full z-30 md:px-2">
      <div
        className="max-w-5xl w-full md:w-11/12 lg:w-4/6 rounded-t-2xl px-4 py-5 mx-auto
        hidden md:flex justify-between items-stretch divide-x divide-pblue-80
        text-2xl font-extrabold leading-none bg-white border-t-2 border-bgreen-50 text-pblue-80
        text-center"
      >
        {links.map(({ link, label }) => (
          <a
            key={link}
            className="flex-1 text-center"
            href={`#${link}`}
            onClick={() => jump(`#${link}`)}
          >
            {label ?? link.toUpperCase()}
          </a>
        ))}
        <a className="flex-shrink-0 pl-4 " href="#top">
          <BiArrowToTop className="h-6" />
        </a>
      </div>
      <div className="border-t-2 border-bgreen-50 md:hidden relative">
        <Disclosure>
          {({ close }) => (
            <>
              <Transition
                enter="transition duration-200 ease-out"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
              >
                <Disclosure.Panel className="grid grid-cols-2 bg-white text-center items-center gap-6 px-4 text-2xl font-semibold text-pblue-80 leading-none py-4 border-b">
                  {links.map(({ link, label }) => (
                    <a
                      key={link}
                      href={`#${link}`}
                      onClick={() => {
                        jump(`#${link}`)
                        close()
                        ;(document.activeElement as HTMLAnchorElement)?.blur()
                      }}
                    >
                      {label ?? link.toUpperCase()}
                    </a>
                  ))}
                </Disclosure.Panel>
              </Transition>
              <div className="w-full flex items-center bg-white m-0">
                <Disclosure.Button
                  tabIndex={-1}
                  className="py-2 pl-8 text-2xl w-full font-bold text-center bg-white text-black"
                >
                  MENU
                </Disclosure.Button>
                <a
                  className="flex-shrink-0 px-1 items-center"
                  href="#top"
                  onClick={() => {
                    jump("#top")
                    close()
                    ;(document.activeElement as HTMLAnchorElement)?.blur()
                  }}
                >
                  <BiArrowToTop className="h-8 w-8" />
                </a>
              </div>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  )
}
