import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { FC } from "react"

import { Section } from "./Section"

export const Story: FC = () => {
  const bgImg = useStaticQuery(
    graphql`
      query {
        images: file(relativePath: { eq: "parts/dispenser00.png" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Section id="story">
      <div className="flex flex-col md:flex-row md:gap-4">
        <div className=" stroke-2 flex flex-col gap-2">
          <p>プレイヤーは冒険者の一団です。</p>
          <p>
            今日のクエストを終えて、大衆食堂「樽屋」にやってきました。
            悲しいかな、金欠のあなたたちには、ビール1杯を注文するだけの手持ちもありません。でも大丈夫。
            このお店には、1コイン毎にビールを注げるビアディスペンサーがあるのです。
            冒険者たちは、手持ちのコインを出し合って、各々のジョッキにビールを注いでいくことにしました。
          </p>
          <p>
            まあ、このディスペンサーは壊れかけていて、コインを入れても泡が出るだけで止まってしまうこともあるようですが…ご愛敬、ということで。
            みんなで楽しくビールを飲んで、ハッピーになりましょう！
          </p>
        </div>
        <div className="flex items-end justify-end object-bottom h-full flex-shrink-0 self-end">
          <StaticImage
            src="../images/parts/dispenser00.png"
            alt=""
            width={320}
            placeholder="tracedSVG"
            objectFit="contain"
          />
        </div>
      </div>
    </Section>
  )
}
