import React, { FC, PropsWithChildren } from "react"
import { Helmet } from "react-helmet"

export const Layout: FC<PropsWithChildren<{ pageTitle: string }>> = ({
  pageTitle,
  children,
}) => {
  const desc = `ビールを飲んでハッピーになろう♪ 3〜5人用ボードゲーム！ 「ビールと泡」`
  const title =
    (pageTitle ? `${pageTitle} -` : "") + `ビールと泡 - 3〜5人用ボードゲーム`
  const url = "https://beer.tete-a-tete.ink/"
  const ogImg = "https://beer.tete-a-tete.ink/og-image.jpg"

  return (
    <div className="min-h-screen">
      <Helmet htmlAttributes={{ lang: "ja" }}>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={desc} />
        <link rel="canonical" href={url} />
        <meta property="og:image" content={ogImg} />
        <meta property="og:image:width" content="1576" />
        <meta property="og:image:height" content="1135" />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="beer.tete-a-tete.ink" />
        <meta property="twitter:url" content={url} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content={ogImg} />

        <link rel="shortcut icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-chrome-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
      </Helmet>
      <main>
        <h1>{pageTitle}</h1>
        {children}
      </main>
    </div>
  )
}
