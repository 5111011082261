import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { BaseHTMLAttributes, FC, PropsWithChildren } from "react"

type HeaderId =
  | "game"
  | "spec"
  | "news"
  | "story"
  | "movie"
  | "shop"
  | "spec"
  | "qa"
  | "about"

export const Section: FC<
  PropsWithChildren<{ id?: HeaderId; className?: string }>
> = ({ id, children, className }) => {
  let spaceCls = "pt-12 mt-8"
  if (id === "about") spaceCls = "pt-8 mt-0"
  return (
    <section className="flex justify-center flex-col items-center relative">
      {id && <SectionHeader id={id} />}
      <div
        className={`max-w-5xl w-full md:w-11/12 lg:w-4/6 gap-2 md:gap-3 flex flex-col bg-white rounded-2xl
          px-3 md:px-8 pb-8 bg-opacity-95 relative ${spaceCls} ${className}`}
      >
        {children}
      </div>
    </section>
  )
}

export const SectionHeader: FC<
  { id: HeaderId } & BaseHTMLAttributes<HTMLHeadElement>
> = ({ children, id, ...props }) => {
  switch (id) {
    case "about":
      return <div id={id} style={{ width: 0, height: 0 }} />
    default:
      return (
        <ImgHeader id={id} {...props}>
          {children}
        </ImgHeader>
      )
  }
}

const ImgHeader: FC<{ id: HeaderId } & BaseHTMLAttributes<HTMLHeadElement>> = ({
  children,
  id,
  ...props
}) => {
  const capId = id.toUpperCase()
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "heading" }
        }
      ) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(
              height: 120
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  `)
  const img = getImage(
    data.allFile.nodes.find(
      (n: { relativePath: string }) => n.relativePath === `heading/${capId}.png`
    )
  )
  return (
    <h2 id={id} className="absolute -top-10 z-10" {...props}>
      {img ? <GatsbyImage image={img} alt={capId} /> : <span>{capId}</span>}
    </h2>
  )
}
