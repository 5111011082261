import { HiOutlineInformationCircle } from "@react-icons/all-files/hi/HiOutlineInformationCircle"
import { ImFilePdf } from "@react-icons/all-files/im/ImFilePdf"
import React, { FC } from "react"

import { Section } from "./Section"

export const Game: FC = () => {
  return (
    <Section id="game">
      <div className="flex flex-col md:flex-row gap-2 relative items-stretch">
        <div className="flex-grow flex flex-col gap-2">
          <p>
            このゲームの目的はビールを飲んでハッピーになることです。ハッピー度合いは得点で表現され、ゲーム終了時に最高得点のプレイヤーが勝利します。
          </p>
          <p>
            プレイヤーはコインをビアディスペンサーに入れ、出てきたビールをジョッキに注ぎ、それを飲むことで得点を得ます。各プレイヤーは、ゲーム中に最大2回まで、ジョッキからビールを飲むことができます。
            1度にたくさんのビールを飲むと、効率良く得点できます。
          </p>
          <p>
            各プレイヤーはコインをふせ、裏側をいくつ当てることができるかの最大数を宣言した人がチャレンジの権利を得ます。
            泡が出ずに最後までビールを注げれば大成功。度胸とチャンスを捉えてビールを飲み干しましょう。
          </p>
          <p>
            有名ブラフゲーム「髑髏と薔薇」に似た構造を持ちながら、キャラクターの特殊能力も駆使してスピーディーな駆け引きを楽しむゲームです。
            モチーフを活かしたコンポーネントが楽しく、また、慣れると１ゲームはかなり短くなります。
          </p>
          <div className="flex-grow" />

          <div className="self-end flex gap-2">
            <a
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text rounded font-bold text-white bg-bgreen-50 hover:bg-bgreen-70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bgreen-50"
              href="./beer-score-calc.jpg"
            >
              <HiOutlineInformationCircle className="h-6 mr-1" />
              得点計算の例
            </a>
            <a
              onClick={() =>
                window.gtag("event", "page_view", {
                  page_location:
                    "https://beer.tete-a-tete.ink/howtoplaybeerorair.pdf",
                  page_title: "ビールと泡ルールブック PDF",
                })
              }
              download="ビールと泡ルールブック.pdf"
              href="./howtoplaybeerorair.pdf"
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text rounded font-bold text-white bg-bgreen-50 hover:bg-bgreen-70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bgreen-50"
            >
              <ImFilePdf className="h-6 mr-1" />
              ゲームルール PDF
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}
