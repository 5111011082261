import { StaticImage } from "gatsby-plugin-image"
import React, { FC } from "react"

import { Section } from "./Section"

export const Shop: FC = () => {
  return (
    <Section id="shop">
      <div className="flex flex-col gap-6">
        <div className="flex gap-2 items-center">
          <a href="https://gamemarket.jp/game/179186">
            <StaticImage
              className="shadow-md"
              src="https://image.gamemarket.jp/ogp.png"
              alt="ゲームマーケット"
              height={80}
            />
          </a>
          <div>
            2022春 2日目 ブース番号: ウ-20
            <br />
            <a
              className="text-bgreen-30 font-bold"
              href="https://docs.google.com/forms/d/e/1FAIpQLSflqE3QbpTZoju6EU-GK8-t_8hZxg1j6bxbb0u26slBksMuIQ/viewform"
            >
              前日まで取り置き受付中です!
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-2 md:items-center">
          <a href="https://tete-a-tete.booth.pm/items/3800555">
            <StaticImage
              alt="BOOTH"
              className="shadow-md"
              src="../images/banners/booth.png"
            />
          </a>
        </div>
        <div className="flex flex-col md:flex-row gap-2 md:items-center">
          <a href="https://shop.yellowsubmarine.co.jp/products/detail/86086">
            <StaticImage
              className="shadow-md"
              alt="イエローサブマリン"
              src="../images/banners/yellow-submarine.png"
              height={60}
            />
          </a>
        </div>
      </div>
    </Section>
  )
}
