import React, { FC, PropsWithChildren } from "react"

import { Section } from "./Section"

export const Spec: FC = () => {
  const Item: FC<PropsWithChildren<unknown>> = ({ children }) => (
    <div className="py-2 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      {children}
    </div>
  )
  const Dt: FC<PropsWithChildren<unknown>> = ({ children }) => (
    <dt className="font-bold">{children}</dt>
  )
  const Dd: FC<PropsWithChildren<unknown>> = ({ children }) => (
    <dd className="text-gray-900 sm:mt-0 sm:col-span-2">{children}</dd>
  )

  return (
    <Section id="spec">
      <div className=" overflow-hidden">
        <div className="sm:border-t sm:border-gray-200 px-4 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-300">
            <Item>
              <Dt>タイトル</Dt>
              <Dd>ビールと泡 (BEER OR AIR)</Dd>
            </Item>
            <Item>
              <Dt>プレイ時間</Dt>
              <Dd>15分～20分</Dd>
            </Item>
            <Item>
              <Dt>プレイ人数</Dt>
              <Dd>3～5人</Dd>
            </Item>
            <Item>
              <Dt>対象年齢</Dt>
              <Dd>6歳以上</Dd>
            </Item>
            <Item>
              <Dt>内容物</Dt>
              <Dd>
                <ul className="list-disc ml-4">
                  <li>化粧箱</li>
                  <li>ビアジョッキ 5個</li>
                  <li>トークン 25枚</li>
                  <li>コースター 5枚</li>
                  <li>シール</li>
                  <li>カード(スタンダードサイズ） 10枚</li>
                  <li>説明書</li>
                </ul>
              </Dd>
            </Item>
            <Item>
              <Dt>頒布価格</Dt>
              <Dd>
                イベント価格: 3500円 <br />
                委託価格: (未定)円
              </Dd>
            </Item>
            <Item>
              <Dt>ゲームデザイン</Dt>
              <Dd>
                ぐる,{" "}
                <a
                  className="text-yellow-800"
                  href="https://twitter.com/yuunagi_show"
                >
                  夕凪ショウ
                </a>
              </Dd>
            </Item>
            <Item>
              <Dt>グラフィック</Dt>
              <Dd>
                <a
                  className="text-yellow-800"
                  href="https://twitter.com/yuunagi_show"
                >
                  夕凪ショウ
                </a>
              </Dd>
            </Item>
            <Item>
              <Dt>サークル</Dt>
              <Dd>
                <a
                  href="https://yuunagi-show.hatenablog.com/"
                  className="text-yellow-800"
                >
                  tete-a-tete (テータテート)
                </a>
              </Dd>
            </Item>
          </dl>
        </div>
      </div>
    </Section>
  )
}
