import { graphql, useStaticQuery } from "gatsby"
import { ImageDataLike } from "gatsby-plugin-image"

const galleryImageQuery = graphql`
  query getCardSamples {
    allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "forGALLERY" }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(width: 1024, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`

export const useGalleryImages = (): ImageDataLike[] => {
  return useStaticQuery(galleryImageQuery).allFile.nodes
}
