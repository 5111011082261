import { FaAngleLeft } from "@react-icons/all-files/fa/FaAngleLeft"
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import React, { FC, useEffect, useRef } from "react"

import { useGalleryImages } from "../templates/gallery-images"

const CarouselSlide: FC<{
  index: number
  num: number
  image: IGatsbyImageData
}> = ({ index, num, image }) => {
  const check = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (index === 0 && check?.current) check.current.checked = true
  }, [index, check])

  return (
    <>
      <input
        className="carousel-open"
        type="radio"
        id={`carousel-${index}`}
        name="carousel"
        aria-hidden="true"
        hidden={true}
        ref={check}
      />
      <GatsbyImage
        className="carousel-item absolute opacity-0 bg-center"
        style={{ position: undefined }}
        objectFit="contain"
        objectPosition="center"
        image={image}
        alt=""
      />
      <label
        htmlFor={`carousel-${(((index - 1) % num) + num) % num}`}
        className={`control-${index} carousel-control w-10 h-10 ml-2 md:ml-4 absolute cursor-pointer font-bold text-gray-500
        hover:text-white rounded-full opacity-90 bg-white/60 hover:bg-blue-300/80 z-10 inset-y-0 left-0 my-auto hidden items-center justify-center`}
      >
        <FaAngleLeft className="h-6" />
      </label>
      <label
        htmlFor={`carousel-${(index + 1) % num}`}
        className={`next carousel-control control-${index} w-10 h-10 mr-2 md:mr-4 absolute cursor-pointer hidden items-center justify-center font-bold text-gray-500
        hover:text-white rounded-full opacity-90 bg-white/60 hover:bg-blue-300/80 text-center z-10 inset-y-0 right-0 my-auto`}
      >
        <FaAngleRight className="h-6" />
      </label>
    </>
  )
}

const CarouselIndicator: FC<{ index: number }> = ({ index }) => {
  return (
    <li className="inline-block mr-3">
      <label
        htmlFor={`carousel-${index}`}
        className="carousel-bullet cursor-pointer block text-4xl text-gray-200 hover:text-blue-300"
      >
        •
      </label>
    </li>
  )
}

export const Carousel: FC = () => {
  const images = useGalleryImages()
    .map((node) => getImage(node))
    .filter(Boolean) as IGatsbyImageData[]
  return (
    <div className="carousel relative rounded-lg overflow-hidden flex-shrink-0 w-full">
      <div className="carousel-inner relative overflow-hidden w-full">
        {images.map((img, index: number) => (
          <CarouselSlide
            key={index}
            index={index}
            image={img}
            num={images.length}
          />
        ))}

        <ol className="carousel-indicators">
          {images.map((_, i) => (
            <CarouselIndicator key={i} index={i} />
          ))}
        </ol>
      </div>
    </div>
  )
}
